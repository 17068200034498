@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --border-color: #01579b;
  --fill-color: #03a9f4;
  --danger-color: #df4759;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  margin: 0;
  height: 100vh;
}

header {
  background-color: #03a9f4;
  color: #ffffff;
  width: 100%;
  height: 60px;
}

header .header-container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

header .header-container h3 {
  padding: 0 10px;
  margin: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-flex: 1 1;
          flex: 1 1;
  font-size: 25px;
}

header .header-container button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
  height: 100%;
  transition: background-color 0.2s ease-in-out;
  padding: 20px;
}

@media (hover: hover) and (pointer: fine) {
  header .header-container button:hover {
    background-color: #039be5;
  }
}

header .header-container span {
  margin-right: 10px;
}

header .header-container .loading {
  padding: 20px;
}

.container {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: -webkit-filter 0.4s ease-in-out;
  transition: filter 0.4s ease-in-out;
  transition: filter 0.4s ease-in-out, -webkit-filter 0.4s ease-in-out;
}

.cup {
  background-color: #ffffff;
  color: #01579b;
  color: var(--border-color);
  border: 4px solid #01579b;
  border: 4px solid var(--border-color);
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}

.cup.full {
  background-color: #03a9f4;
  background-color: var(--fill-color);
  color: #ffffff;
}

.cup.large {
  width: 250px;
  height: 300px;
  margin: 0 0 20px 0;
}

.cup.small {
  width: 50px;
  height: 100px;
  border-radius: 0 0 20px 20px;
  border-width: 2px;
  transition: background-color 0.2s ease-in-out;
}

.cup.historical {
  width: 50px;
  height: 70px;
  border-radius: 0 0 20px 20px;
  border-width: 2px;
}

@media (hover: hover) and (pointer: fine) {
  .cup.small:hover {
    background-color: #03a9f4;
    background-color: var(--fill-color);
  }
}

.cup .remained {
  height: 100%;
  transition: height 0.2s ease-in-out;
}

.cup .drank {
  background-color: #03a9f4;
  background-color: var(--fill-color);
  transition: height 0.2s ease-in-out;
}

.cup .text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.cup.large .text {
  font-size: 20px;
  font-weight: bold;
}

.cup.small .text {
  font-size: 15px;
  transition: color 0.2s ease-in-out;
}

@media (hover: hover) and (pointer: fine) {
  .cup.small:hover .text {
    color: #ffffff;
  }
}

.container .small-cups-container {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

.container .small-cups-container .cup-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.container .small-cups-container .cup-container .label {
  margin-top: 5px;
  color: #01579b;
  color: var(--border-color);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.container .small-cups-container .cup-container .cup.small.plus {
  border-style: dotted;
}

.historical-cups-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.historical-cups-container .cup-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.historical-cups-container .cup-container .label {
  margin-top: 5px;
  color: #01579b;
  color: var(--border-color);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.container .icon.loading {
  color: #01579b;
  color: var(--border-color);
}

.container .error {
  color: #01579b;
  color: var(--border-color);
  font-size: 20px;
}

.menu {
  position: absolute;
  top: 60px;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 1;
  transition: background-color 0.4s ease-in-out;
}

.menu .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transform: translateX(-1000px);
          transform: translateX(-1000px);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  background-color: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.menu.show .background {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu .background-first {
  background-color: #03a9f4;
  background-color: var(--fill-color);
  min-width: 300px;
  transition-delay: 0.2s;
}

.menu.show .background-first {
  transition-delay: 0s;
}

.menu.show {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
}

.menu .menu-container {
  height: 100%;
  width: 95%;
  -webkit-transform: translateX(-1000px);
          transform: translateX(-1000px);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  background-color: #ffffff;
  transition-delay: 0s;
}

.menu.show .menu-container {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition-delay: 0.2s;
}

.menu .menu-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu .menu-container ul li {
  cursor: pointer;
  padding: 20px 10px;
}

@media (hover: hover) and (pointer: fine) {
  .menu .menu-container ul li:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.menu .menu-container ul li button {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 17px;
  cursor: pointer;
  font-family: inherit;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #01579b;
  color: var(--border-color);
}

.menu .menu-container ul li button .icon {
  margin-right: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  max-width: 400px;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 5px;
  padding: 20px 40px;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.modal .modal-container .title {
  font-size: 20px;
  margin: 5px 0;
  color: #01579b;
  color: var(--border-color);
}

.modal .modal-container .error {
  font-size: 13px;
  margin-top: 5px;
  color: #df4759;
  color: var(--danger-color);
}

.modal .modal-container input {
  border: none;
  outline: none;
  border-bottom: 2px solid #01579b;
  border-bottom: 2px solid var(--border-color);
  padding: 10px 0;
  color: #01579b;
  color: var(--border-color);
}

.modal .modal-container .buttons-container {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.modal .modal-container .buttons-container button {
  margin-left: 20px;
  border: none;
  outline: none;
  background-color: #01579b;
  background-color: var(--border-color);
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
  padding: 10px 20px;
  border-radius: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.modal .modal-container .buttons-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.modal .modal-container .buttons-container button.danger {
  background-color: #df4759;
  background-color: var(--danger-color);
}

@media(max-width: 768px) {
  .container.blur {
    -webkit-filter: blur(20px);
            filter: blur(20px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.loading .cup .remained {
  animation: fill-water 1s linear infinite reverse;
}

.loading .cup .drank {
  -webkit-animation: fill-water 1s linear infinite;
          animation: fill-water 1s linear infinite;
}

@-webkit-keyframes fill-water {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes fill-water {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}
